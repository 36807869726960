$blue-color: #2fb5d9;

$blue-black-color: #225765;
$black-black-color: #04242d;
$grey: #71939c;

//mostly for table data
$red-color: #ff3739;
$green-color: #15a323;
$custom-green-color: #04a7a7;
$amber-color: #fe9603;
$purple-color: #d33db2;
$blue-color: #2fb5d9;

// $secondary-color: #61ce70;
// $orange-color: #fb6d3a;
// $yellow-color: #ffc247;
// $grey-color: #e5e5e5;
// $text-muted-color: #6c757d;
// $white-color: #ffffff;
// $light-color: #f6f6f5;

//real

//colors
$background-color: #f7f7f7;
$nav-background-color: #fbfbfb;
$icons-box-bg: #d5f0f7;
$gradient-bg: linear-gradient(#00d2a8, #0693b8);

$blue-color: #03c2fe;
$deep-blue-color: #002ed0;
$deep-purple-color: #6212b1;
$blue-color: #03c2fe;
$green-color: #00d2a8;
$orange-color: #fe9603;
$deep-orange-color: #e68300;
// $red-color: #ff3739;

$red-bg: #f6f1f3;

$pry-bg: #f5fbfd;
