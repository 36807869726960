@import "variables";

.sidebar {
  position: fixed;
  top: 0px;
  width: 250px;
  // background-color: red !important;
  height: 100%;
  background:white;
  overflow-y: scroll;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 768px) {
  .sidebar {
    right: -300px;
    transition: right 700ms;
    z-index: 1000;
    .close-icon {
      margin-right: 90%;
    }

    .base-card {
      margin: auto;
    }
  }

  .expanded {
    right: 0px;
    z-index: 1000;
    transition: right 700ms;
  }
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  border: none !important;
  color: #71939c !important;
  cursor: pointer;
  padding: 8px 10px !important;
  width: 80% !important;
  margin: 10px 20px;
  .side-navigation-panel-select-option-text {
    font-weight: 600 !important;
  }
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: transparent !important;
  background: #04a7a71a !important;
  border-color: transparent !important;
  font-weight: bold;
  border-radius: 5px;
  color: #04a7a7 !important;
}

.side-navigation-panel-select-option {
  &:hover {
    background-color: #f5fbfd !important;
    border-color: #f5fbfd !important;
  }
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap {
  .side-navigation-panel-select-inner-option {
    &:hover {
      background-color: white !important;
      // background-color: #f5fbfd !important;
    }
    --tw-text-opacity: 1;
    align-items: center;
    border: none;
    // color: rgba(55, 65, 81, var(--tw-text-opacity));
    color: #225765 !important;
    // background-color: red !important;
    // font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2.3rem !important;
    width: 100%;
  }
  .side-navigation-panel-select-inner-option {
    .side-navigation-panel-select-inner-option-wrap {
      &:hover {
        background-color: #e6f6f6;
      }

      border-radius: 3px;
      color: #04242d !important;
      padding: 8px 12px;
      width: 300px;
    }
  }
  .side-navigation-panel-select-inner-option-selected {
    background-color: transparent !important;
    // background-color: transparent !important;
    .side-navigation-panel-select-inner-option-wrap {
      background-color: #e6f6f6;
      border-radius: 3px;
      color: #04242d !important;
      padding: 8px 12px;
      font-weight: bold;
      // .side-navigation-panel-select-inner-option-text {
      //   color: red !important;
      // }
    }
  }
}
