@import "variables";

.page-body::-webkit-scrollbar {
  display: none;
}

.s-layout {
  display: flex;
  //   padding-top: 2.5vh;
  //   padding-bottom: 2.5vh;
  .page-body {
    background: #f8f9fa !important;
    margin-left: 250px;
    // border-radius: 20px 0 0 20px;
    // height: 95vh;
    // overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    // padding: 20px;
    background-color: red;
    width: calc(100% - 250px) !important;
  }
}

@media only screen and (max-width: 768px) {
  .s-layout {
    padding-top: 0;
    padding-bottom: 0;

    .page-body {
      height: 100%;
      min-height: 100vh;
      margin-left: 0 !important;
      border-radius: 0;
      width: 100% !important;
    }
  }
}

.pages-overlay {
  background: rgb(0, 0, 0, 0.3);
}
