@import "variables";

.top-nav {
  background: url("../../../public/images/nav-bg2.png"), rgba(4, 36, 45, 0.90);

  height: 75px;
  // position: relative;
      // position: sticky !important;

}

.icons-box {
  padding: 2px 6px;
  background-color: $icons-box-bg;
  border-radius: 10px;
  height: 30px;
  width: 30px;
}
.layer {
  background-color: rgba(4, 36, 45, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sidebar-control {
  z-index: 1000 !important;
}

@media only screen and (max-width: 768px) {
  .top-nav {
    // position: sticky !important;
    // background: $background-color;
    // height: 60px;
    // .icons-box {
    //   padding: 2px 6px;
    //   background-color: $icons-box-bg;
    //   border-radius: 10px;
    // }
  }
}
