@import 'variables';
@import 'react-datepicker/dist/react-datepicker';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  //   background: black;
  background-color: $background-color;

  #root {
    height: 100%;
    font-size: 14px;
  }
}

.App {
  height: 100%;
  font-family: 'Inter';
  color: $blue-black-color;
  // font-size: 22px;
}

.cursor-pointer {
  cursor: pointer;
}

// .pro-sidebar > .pro-sidebar-inner {
//   background: $background-color;
// }

.gradient-btn {
  background: $gradient-bg !important;
}

.white-btn {
  background: #fff !important;
}

.gradient-text {
  background: $gradient-bg !important;
  font-size: 200 !important;
  background-size: 100%;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.capitalize {
  text-transform: capitalize;
}

td,
th {
  white-space: nowrap;
}

.cus-striped-table {
  tr {
    background-color: red !important;
    border-color: white !important;
    border-width: 7px !important;
  }
}

.form-check-input:checked {
  background-color: $green-color !important;
  border-color: $green-color !important;
}

input[type='checkbox'] :checked {
  background-color: $green-color;
  border-color: $green-color;
}

.active > .page-link,
.page-link.active {
  color: white !important;
  font-weight: bold;
  background-color: $green-color;
  border-color: $green-color !important;
  border-radius: 6px;
}

.card-title {
  font-weight: 600;
  color: #225765;
}

.card {
  box-shadow: none !important;
  outline: none !important;
}

.red-pill {
  padding: 5px 15px;
  color: $red-color;
  background-color: $red-bg;
  border-radius: 16px;
}

.in-input:focus {
  border: 0 !important;
  outline: 0;
}

.list-hover-bg {
  background-color: #f5fbfd;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
  border: none;
}

.two-way-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.three-way-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}
.bold-brcmb {
  font-weight: 900 !important;
}

.no-wrap {
  white-space: nowrap;
}
// textarea:focus,
// textarea.form-control:focus,
// input.form-control:focus,
// input[type="text"]:focus,
// input[type="password"]:focus,
// input[type="email"]:focus,
// input[type="number"]:focus,
// [type="text"].form-control:focus,
// [type="password"].form-control:focus,
// [type="email"].form-control:focus,
// [type="tel"].form-control:focus,
// [contenteditable].form-control:focus {
//   box-shadow: inset 0 -0.1px 0 #ddd;
// }

.focus-no-outline:focus {
  box-shadow: inset 0 -0.1px 0 #ddd;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table-check {
  width: 30px;
}

th {
  color: $blue-black-color;
  background: transparent!important;
  padding: 1% 10px;
  margin-bottom: 200px;
}

td {
  color: $black-black-color;
  background: transparent!important;
  border-bottom: 1px solid #e6eef0 !important;
  padding: 0 10px;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f5fbfd !important;
}

.table-hover thead tr,
.table-hover thead th {
  background-color: #f5fbfd !important;
  border-color: #f5fbfd !important;
  cursor: pointer;
  font-weight: bold;
}

.chart-container {
  height: 190px;
}


.one-column-hover:hover {
  background-color: #f5fbfd!important;
}

@media only screen and (min-width: 500px) {
  .chart-container {
    height: 220px;
  }
}
@media only screen and (min-width: 700px) {
  .chart-container {
    height: 240px;
  }
}
@media only screen and (min-width: 870px) {
  .chart-container {
    height: 260px;
  }
}

.card {
  border: 0.5px solid #cddce0 !important;
}

.table-pill {
  padding: 7px 13px;
  border-radius: 16px;
  background-color: $icons-box-bg;
}

.detail-card-data {
  margin-right: 80px;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.bold-brcmb {
  font-weight: 900 !important;
}

// .small, small {
//   font-size: .80em !important;
// }

td {
  font-size: 13px !important;
  font-weight: normal;
}

.p-sticky {
  position: sticky;
}

a {
  color: #0692f6 !important;
}

#onboardingComment {
  resize: none !important;
}

#onboardingComment:focus {
  outline: 0;
}

.m-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #08032c33;
  margin: 1em 0;
  padding: 0;
}

.perf-overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.perf-overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ck-toolbar {
  position: absolute !important;
  bottom: 1px !important;
  width: calc(100% - 2px) !important;
  left: 1px;
  margin-top: 30px;
}

.ck.ck-editor__editable_inline {
  min-height: 200px !important;
}
.ck .ck-powered-by {
  display: none;
}
